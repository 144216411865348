//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Options from './sharedIconProps';

export default {
  name: 'Star',
  props: { ...Options.props },
  data() {
    return {
      viewBox: '0 0 60 59',
    };
  },
};
